@import "../variable/variable.scss";
@import "../variable/mixin.scss";

footer {
  padding: $section-padding;
  background-color: $secondery-color;
  .footer_info {
    @include flexBetwn;
    align-items: stretch;

    .footer_wrap {
      @include flexBetwn;
      align-items: stretch;
      column-gap: 3rem;
      .items {
        h2 {
          font-size: 2rem;
          padding-bottom: 1rem;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
        ul {
          li {
            display: block;
            a {
              color: #000;
              line-height: 2.2rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
    .logo_wrap {
      h1 {
        padding-bottom: 1.5rem;
        a {
          font-size: $heading-size;
          font-family: "Libre Barcode 39 Text", cursive;
          color: $hding-color;
          font-weight: 500;
        }
      }
      ul {
        li {
          padding-right: 1rem;
          a {
            color: #fff;
            padding: 11px 12px;
            background-color: #000;
            font-size: 1rem;
            border-radius: 50px;
          }
          .fb {
            padding: 11px 15px;
          }
        }
      }
      p {
        padding-top: 1.5rem;
      }
    }
  }
  .footer_bottom {
    p {
      text-align: center;
      font-size: 0.8rem;
      margin-top: 3rem;
      padding-top: 1rem;
      border-top: 1px solid rgba(255, 255, 255, 0.24);
    }
  }
}

@media screen and (max-width: 448px) {
  footer {
    padding: 3rem 0 2rem  ;
    background-color: $secondery-color;
    .footer_info {
      flex-direction: column-reverse;
      align-items: stretch;
      row-gap: 2rem;
      .footer_wrap {
        align-items: stretch;
        column-gap: 3rem;
        .items {
          h2 {
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
            font-weight: 600;
            letter-spacing: 0.5px;
          }
          ul {
            li {
              display: block;
              a {
                color: #000;
                line-height: 1rem;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
      .logo_wrap {
        h1 {
          padding-bottom: 1.5rem;
          a {
            font-size: 2rem;
        
          }
        }
        ul {
          li {
            padding-right: 1rem;
            a {
              color: #fff;
              padding: 8px 9px;
              background-color: #000;
              font-size: 0.8rem;
              border-radius: 50px;
            }
            .fb {
              padding: 8px 12px;
            }
          }
        }
        p {
          padding-top: 1rem;
          font-size: 0.8rem;
        }
      }
    }
    .footer_bottom {
      p {
        text-align: center;
        font-size: 0.8rem;
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.24);
      }
    }
  }
}
